import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home";
// import defaultLayout from "./layouts/side-nav-inner-toolbar";
import menuApplication from "./layouts/menu-application";
import none from "./layouts/none";
// import simpleLayout from "./layouts/single-card";

// function loadView(view) {
//     return () => import(`./views/${view}.vue`);
// }
function loadViewIndex(view) {
    return () => import(`./views/${view}/index.vue`);
}

export default function ($Core) {
    const router = new createRouter({
        routes: [
            {
                path: "/",
                name: "home",
                meta: {
                    requiresAuth: true,
                    layout: none,
                },
                component: loadViewIndex("Tra-Cuu-Lenh"),
            },
            {
                path: "/Tra-Cuu-Lenh",
                name: "TraCuuLenh",
                meta: {
                    requiresAuth: true,
                    layout: none,
                },
                component: loadViewIndex("Tra-Cuu-Lenh"),
            },
            {
                path: "/Chi-Tiet-Lenh",
                name: "ChiTietLenh",
                meta: {
                    requiresAuth: true,
                    layout: none,
                },
                component: loadViewIndex("Chi-Tiet-Lenh"),
            },
            {
                path: "/Xem-Ban-The-Hien-Lenh",
                name: "XemBanTheHienLenh",
                meta: {
                    requiresAuth: true,
                    layout: none,
                },
                component: loadViewIndex("Xem-Ban-The-Hien-Lenh"),
            },
            {
                path: "/Huong-Dan-Tai-Lenh-Iphone",
                name: "Huong-Dan-Tai-Lenh-Iphone",
                meta: {
                    requiresAuth: true,
                    layout: none,
                },
                component: loadViewIndex("Huong-Dan-Tai-Lenh-Iphone"),
            },
            {
                path: "/",
                redirect: "/home",
            },
            {
                path: "/recovery",
                redirect: "/home",
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: "/home",
            },
        ],
        history: createWebHistory(),
    });

    router.beforeEach((to, from, next) => {
        console.log("abcd", $Core);
        if (to.name === "login-form" && $Core.Auth.IsAuth) {
            next({ name: "home" });
        }
        next();

        // if (!$Core.Auth.IsAuth) {
        //     $Core.Auth.Login();
        //     // next({
        //     //     name: "login-form",
        //     //     query: { redirect: to.fullPath }
        //     // });
        // } else {
        //     next();
        // }
    });
    return router;
}
