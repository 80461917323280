export default {
    namespaced: true,
    state: () => ({
        manHinh: "doc", //true 1080x1920; false 1920x1080
        screenWidth: 0,
        screenHeight: 0,
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },
    actions: {},
};
