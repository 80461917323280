export default {
    namespaced: true,
    state: () => ({
        CheckTaoTaiKhoan: true,
        DanhSachTraCuuLenh: [],
        ThongTinTraCuu: {
            OptionTraCuu: "BienKiemSoat",
            MaSoThue: "",
            BienKiemSoat: "",
            NgayKhoiHanh: new Date(),
            MaLenh: null,
        },
        ChiTietLenh: {},
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async CapNhatTaiKhoan(store, query) {
            store;
            try {
                let result = await this.$Core.Api.TaiKhoan(
                    this.$i18n.global.t("urlTaiKhoan.CapNhatTaiKhoan")
                ).Post(query);
                return result.Data;
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
