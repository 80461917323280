<template>
    <slot />

    <DxPopup
        v-if="ifPopupThongBao"
        v-model:visible="PopupThongBao"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :width="ParamsThongBao.width"
        height="auto"
        @hidden="onHidden('PopupThongBao')"
    >
        <NotificationVue
            :Params="ParamsThongBao"
            :ButtonLeft="false"
            @click="XacNhanThongBao"
        />
    </DxPopup>
</template>

<script>
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";
import { DxPopup } from "devextreme-vue";
import notify from "devextreme/ui/notify";
export default {
    components: {
        NotificationVue,
        DxPopup,
    },
    data() {
        return {
            ifPopupThongBao: false,
            PopupThongBao: false,
            ParamsThongBao: {
                width: 440,
                state: "Error",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
                title: "Cảnh báo!",
                message_title: "Bạn muốn thoát khỏi hệ thống?",
            },
        };
    },
    methods: {
        onHidden(name) {
            this["if" + name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        XacNhanThongBao(bool) {
            if (bool == true) {
                this.PopupThongBao = false;
                setTimeout(() => {
                    this.emitter.emit("XacNhanThongBao", {
                        bool,
                        state: this.ParamsThongBao.state,
                    });
                }, 100);
            }
        },
        showToast(params) {
            notify(
                {
                    message: params.message,
                    height: 45,
                    width: 150,
                    minWidth: 150,
                    type: params.type,
                    displayTime: 3500,
                    animation: {
                        show: {
                            type: "fade",
                            duration: 400,
                            from: 0,
                            to: 1,
                        },
                        hide: { type: "fade", duration: 40, to: 0 },
                    },
                },
                { position: "bottom center", direction: "up-push" }
            );
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("onToast", ({ type = "error", message = "" }) => {
            self.showToast({ type, message });
        });
        this.emitter.on(
            "ThongBaoPopup",
            ({
                type = "Error",
                title = "Thông báo",
                message_title = "",
                message = "",
                textButton = "Đồng ý",
                width = 440,
            }) => {
                self.ParamsThongBao.state = type;
                self.ParamsThongBao.button_save = textButton;
                self.ParamsThongBao.title = title;
                self.ParamsThongBao.message_title = message_title;
                self.ParamsThongBao.message = message;
                self.ParamsThongBao.width = width;
                self.showPopup("PopupThongBao");
            }
        );
    },
};
</script>
