import TaiKhoanLaiXe from "./stores/TaiKhoanLaiXe";
import ManHinh from "./stores/ManHinh";
import TraCuuLenh from "./stores/TraCuuLenh";
export default {
    modules: {
        TaiKhoanLaiXe: TaiKhoanLaiXe,
        ManHinh,
        TraCuuLenh,
    },
};
